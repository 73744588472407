<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑部门' : '新增部门'"></modalHeader>
			</template>
			
			<div>
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
					<a-form-item label="所属单位" name="unitId" :rules="[{ required: true, message: '请选择所属单位!' }]">
						<a-select v-model:value="modelRef.unitId" placeholder="请选择所属单位">
							<a-select-option v-for="(item, index) in $store.state.count.unitList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="部门名称" name="name" :rules="[{ required: true, message: '请输入部门名称!' },{ max: 10, min: 1, message: '部门名称请保持1~10字符之间!' }]">
						<a-input v-model:value="modelRef.name" placeholder="请输入部门名称"></a-input>
					</a-form-item>
					
					<a-form-item label="股长" name="userId">
						<!-- <a-select v-model:value="modelRef.userId" placeholder="请选择股长">
							<a-select-option v-for="(item, index) in $store.state.count.userList" :key="item.id" :value="item.id">{{ item.nickname }}</a-select-option>
						</a-select> -->
						<a-select show-search
							v-model:value="modelRef.userId"
							placeholder="请输入股长名称"
							:default-active-first-option="false"
							:show-arrow="false"
							:filter-option="false"
							:not-found-content="null"
							:options="userList"
							:field-names="{value:'id',label:'username',options: 'userList'}"
							@search="HandleSearch"
							@change="handleChange"
						></a-select>
					</a-form-item>
					
					<a-form-item label="办公室" name="office">
						<a-input v-model:value="modelRef.office" placeholder="请输入办公室"></a-input>
					</a-form-item>
				</a-form>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import eventBus from '@/utils/eventBus.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import { getAllUserList } from '@/service/modules/global.js';
	import { saveDepartment, updateDepartment } from '@/service/modules/unit.js';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				visible: false,
				isEdit: false,
				isOpen: false,
				modelRef: {},
				oldTimeout: null,
				userList:[],
				loading:false
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await updateDepartment(postData);
						} else {
							ret = await saveDepartment(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							eventBus.$emit('refreshDepartment');
							this.$emit('refresh');
							this.close();
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						name: e.name,
						unitId: e.unitId,
						userId: e.userId || undefined,
						office: e.office
					}
					this.isEdit = true;
				} else {
					this.isEdit = false;
					this.modelRef = {}
				}
				this.visible = true;
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.modelRef = {}
				this.visible = false;
			},
			HandleSearch(value) {
				if (this.oldTimeout) clearTimeout(this.oldTimeout);
				this.oldTimeout = setTimeout(() => {
					this.getData(value)
				}, 1000);
			},
			async getData(item) {
				this.loading = true;
				try {
					let ret = await getAllUserList({
						usernameOrPhone: item,
					});
					this.loading = false;
					if (ret.code === 200) {
						if (ret.data.length === 0) {
							this.$message.warning('暂无该用户');
						}else{
							this.userList= ret.data.map(item => {
								return {
									id: item.id,
									username: item.phone+ '-' + item.nickname
								}
							});
						}
					}
				} catch(e) {
					this.loading = false;
				}
			},
		}
	}
</script>

<style>
</style>